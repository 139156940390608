@tailwind base;
@tailwind components;
@tailwind utilities;

.tstm-photo{
    clip-path: polygon(10% 0, 100% 0%, 90% 100%, 0% 100%);
  }

  .animated-item {
    display: inline-block;
    animation: rotate 1s linear infinite;
    animation-play-state: paused;
    animation-delay: calc(var(--scroll) * -1s);
  }
  
  @keyframes rotate {
    0% {
      /* transform: rotate(40deg); */
      /* transform: scale(2, 1.1); */
      opacity: 0%;
    }
    40%{
      opacity: 100%;
    }
  }